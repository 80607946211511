import PropTypes from "prop-types"
import React from "react"

var classNames = require("classnames")

export default function BulmaColumns(props) {

    var columnsClasses = classNames("columns", props.className, {

    })

    return(
        <div className={columnsClasses}>
            {props.children}
        </div>
    )
}