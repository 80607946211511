import React, { Component, ReactDOM, useEffect } from "react"
import {isMobileOnly, isBrowser, isMobile, withOrientationChange } from 'react-device-detect';
import ReactPlayer from "react-player"
import aosWatch from "./animations/aosWatch.js"
import PlayPause from "./Sizzle/playPause.js"
import SizzleMp4 from "../videos/Sizzle_New_Desktop.mp4";
import SizzleWebm from "../videos/Sizzle_New_Desktop.webm";
import MobileSizzleMp4 from "../videos/MobileSizzle_V6 - Approved.mp4";
import MobileSizzleWebm from "../videos/MobileSizzle_V6 - Approved.webm";

var classNames = require("classnames")

const mobileVidUrls = [
  {
    src:
      MobileSizzleWebm,
      type: "video/webm"
  },
  {
    src:
    MobileSizzleMp4,
      type: "video/mp4"
  }
]

const defaultVidUrls = [
  {
    src:
      SizzleWebm,
      type: "video/webm"
  },
  {
    src:
    SizzleMp4,
      type: "video/mp4"
  }
]

class SizzleVideo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      playing: true,
      isPhonePortrait: 0,
      vidUrl: (isMobileOnly && !this.props.isLandscape) ? mobileVidUrls : defaultVidUrls,
      hidden: true,
      isLandscape: this.props.isLandscape, 
      isPortrait: this.props.isPortrait,
      isMobileOnly: isMobileOnly
    }
    this.vidRef= React.createRef();
    this.playerWrapper = {}
    this.resize = this.resize.bind(this)
    this.handlePlayPause = this.handlePlayPause.bind(this)
  }
  
  componentDidMount() {
    const video = document.querySelector("video")
    // const cursor = document.querySelector("cursor");
    // var cursor = ReactDOM.findDOMNode(cursor);

    // var cursor = this.findRenderedComponentWithType(
    //   layout,
    //   cursor
    // );

    //aosWatch(this.props.id, true, this.handlePlayPause);
    video.addEventListener("mousedown", e => {
      requestAnimationFrame(() => {
        //this.handlePlayPause();
        //cursor.setState({ pressed: true,circleColor: "#red",cursorColor: "#red"})
      })
    })
    let underTablet = window.innerWidth < 576
    if (underTablet == true) {
      // console.log("!!!Tablet");
      // this.playerWrapper = {
      //   position: "relative",
      //   paddingTop: "100vh",
      // }
      this.setState({ hidden: false, isMobileOnly: isMobileOnly, isLandscape: this.props.isLandscape })
    } else {
      this.playerWrapper = {
        position: "relative",
      }
      // console.log("!!!DEsktop");
      this.setState({hidden: false, isMobileOnly: isMobileOnly, isLandscape: this.props.isLandscape })
    }
    // window.addEventListener("resize", this.resize.bind(this))
    // window.addEventListener("orientationchange", this.resize.bind(this))
  }

  componentDidUpdate(prevState) {

    // if (this.props.isLandscape !== prevState.isLandscape) {
    //   if (this.props.isLandscape || isBrowser ) {
    //     this.setState({ vidUrl: defaultVidUrls, isLandscape: this.props.isLandscape, isPortrait: this.props.isPortrait })
    //   } else {
    //     this.setState({ vidUrl: mobileVidUrls, isLandscape: this.props.isLandscape, isPortrait: this.props.isPortrait })
    //   }
    // }

    if (isMobileOnly) {
      if (this.props.isLandscape !== this.state.isLandscape) {
        if (this.props.isLandscape) {
            this.setState({ vidUrl: defaultVidUrls, isLandscape: this.props.isLandscape, isPortrait: this.props.isPortrait })
        } else {
          this.setState({ vidUrl: mobileVidUrls, isLandscape: this.props.isLandscape, isPortrait: this.props.isPortrait })
        }
      }     
    }


  }

  resize(override) {
    // let width = window.innerWidth;
    // let height = window.innerHeight;
    //
    // if (width > height) {
    //   useDesktopVideo()
    // }
    // if (height > width && windowAR > videoAR) {
    //   useMobileSizzleWidthOneHundred()
    // }
    // else {
    //   useMobileSizzleHeightOneHundred()
    // }

    // let isPhonePortrait =
    //   window.innerWidth < 576 && window.innerWidth < window.innerHeight
    // if (isPhonePortrait !== this.state.isPhonePortrait || override === true) {
    //   this.setState({ isPhonePortrait: isPhonePortrait })
    //   if (this.state.isPhonePortrait == true) {
    //     this.playerWrapper = {
    //       position: "relative",
    //       paddingTop: "100vh"
    //     }
    //     this.setState({ vidUrl: mobileVidUrls })
    //   } else {
    //     this.playerWrapper = {
    //       position: "relative",
    //       paddingTop: "56.25%"
    //     }
    //     this.setState({ vidUrl: defaultVidUrls })
    //   }
    // }

    if (this.props.isLandscape || isBrowser) {
      this.setState({ vidUrl: defaultVidUrls })
    } else {
      this.setState({ vidUrl: mobileVidUrls })
    }
  }

  handlePlayPause() {

    if (this.state.playing) {
      this.vidRef.current.pause();      
      this.setState({playing: false})
    } else {
      this.vidRef.current.play();      
      this.setState({playing: true})
    }

    // console.log(this.state)
  }

  render() {
    // if (this.state.isPhonePortrait == true) {
    //   var playerWrapper = {
    //     position: "relative",
    //     paddingTop: "100vh",
    //   }
    // } else {
    //   var playerWrapper = {
    //     position: "relative",
    //     paddingTop: "56.25%",
    //   }
    // }

    var reactPlayerStyle = {
      top: 0,
      left: 0,
      display: "flex"
    }

    let homePlayerClasses = classNames({
      hidden: this.state.hidden,
      "test-1 home-sizzle": true,
    })

    return (
        <div
          className={homePlayerClasses}
          style={{position: "relative"}}
        >
          {/* <ReactPlayer
            style={reactPlayerStyle}
            url={this.state.vidUrl}
            width="100%"
            height=""
            playing={this.state.playing}
            playsinline
            loop={true}
            muted
            controls={false}
          /> */}
          <video
            ref={this.vidRef}
            style={reactPlayerStyle}
            width="100%"
            height=""
            playsInline
            loop
            muted
            autoPlay
            controls={false}
          >
            {
              this.state.vidUrl.map((video) =>
              {
                return(
                  <source
                  key={video.src}
                  src={video.src}
                  type={video.type}
                  />
                )
              })
            }
          </video>
          <PlayPause
            language={this.props.language ? this.props.language : null}
            handlePlayPause={this.handlePlayPause}
            videoPlaying={this.state.playing}
          />
        </div>        

    )
  }
}

const VideoWrapped = withOrientationChange(SizzleVideo)

export { VideoWrapped }
